/* <script> */
import { checkUserRight } from '@/base/js/baseHelper.js';
import DTH from '@/base/js/DateTimeHelper.js';

export default {
  data () {
    return {
      NULLVALUE: this.$t('base.NULL'),
      DateTimeHelper: DTH,
      paymentModes: [
        { value: 'oneOffPayment', text: this.$t('base.paymentMode.oneOffPayment') },
        { value: 'monthly', text: this.$t('base.paymentMode.monthly') },
        { value: 'quarterly', text: this.$t('base.paymentMode.quarterly') },
        { value: 'biannual', text: this.$t('base.paymentMode.biannual') },
        { value: 'yearly', text: this.$t('base.paymentMode.yearly') }
      ]
    };
  },
  computed: {
  },
  methods: {
    notImplemented (sysMsg = null) {
      this.$globals.Info.open([{ type: 'info', message: 'Diese Funktion ist leider noch nicht implementiert', sysMsg: sysMsg }], sysMsg != null);
    },
    formatValue (value, unit = null, nullValue = this.NULLVALUE) {
      if (value == null) return nullValue;
      if (unit != null) return value + ' ' + unit;
      return value;
    },

    formatEndDate (value, nullValue = DTH.INFINITEDATETIME) {
      if (DTH.isLaterOrEqual(value, DTH.INFINITEDATE)) {
        return this.$t('base.unlimited');
      }
      let ds = DTH.formatDateTime(value, nullValue);
      if (ds.substring(ds.indexOf(',') + 2).startsWith('23:59')) return ds.substring(0, ds.indexOf(','));
      return ds;
    },

    formatBeginDate (value, nullValue = DTH.NOW) {
      if (DTH.isLaterOrEqual(value, DTH.INFINITEDATE)) return this.$t('base.unlimited');
      let ds = DTH.formatDateTime(value, nullValue);
      if (ds.substring(ds.indexOf(',') + 2).startsWith('00:00')) return ds.substring(0, ds.indexOf(','));
      return ds;
    },

    formatArray (arr, maxValues = 2, nullValue = this.NULLVALUE) {
      if (arr.length < maxValues) maxValues = arr.length;
      let s = '';
      for (let i = 0; i < maxValues; i++) {
        if (arr[i] == null) return nullValue;
        s += (i === 0 ? '' : ', ') + arr[i];
      }
      if (arr.length > maxValues) s += ', ...';
      return s;
    },
    replaceAsAll (value) {
      return this.formatValue(value, null, this.$t('base.all'));
    },
    capitalizeString (placeholder) {
      let value = this.$t(placeholder);
      return value[0].toUpperCase() + value.slice(1);
    },
    /**
     * @function checkUserRight
     * @description checks if user has asked right for asked (details)
     * askedRestrictions is an array of following objects
     *  { key: 'brand', value: 'brandname' } or
     *  { key: 'brand', value: ['brandname1', 'brandname2', ...], linkType: 'AND' | 'OR'} - default-linkType is AND
     */
    checkUserRight (userRights, askedRight, askedRestrictions = []) {
      return checkUserRight(userRights, askedRight, askedRestrictions);
    }
  }
};
// </script>
