<template>
  <v-card
    class="minContent"
    v-if="clientSettings != null"
  >
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            v-model="clientSettings.performance"
            :items="performanceList"
            item-text="text"
            item-value="value"
            :label="$t('condAdmin.moduleSettings.performance')"
            @change="setDirty"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="clientSettings.clientWebUrl"
            :label="$t('condAdmin.moduleSettings.clientWebUrl') + $t('base.mandatory')"
            persistent-hint
            :rules="[rules.required]"
            @change="setDirty"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row no-gutters>
            <v-card-title class="pa-0">
              {{ $t('condAdmin.moduleSettings.passThroughProviders') }}
            </v-card-title>
          </v-row>
          <v-row
            no-gutters
            class="py-4"
          >
            <QuickFilter
              :label="$t('base.selected')"
              :count="selectedProviders.length"
              :active="providerQuickFilter === true"
              :deselectValue="null"
              @clicked="applyProviderQuickFilter"
            />
            <QuickFilter
              class="ml-4"
              :label="$t('base.unselected')"
              :count="providerList.length - selectedProviders.length"
              :active="providerQuickFilter === false"
              :selectValue="false"
              :deselectValue="null"
              @clicked="applyProviderQuickFilter"
            />
            <v-spacer />
            <v-icon
              @click="init()"
              color="secondary"
              class="mr-8"
              v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
            >mdi-reload</v-icon>

            <v-menu
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="secondary"
                  v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))"
                >mdi-format-columns</v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-for="(col, index) in availableProviderColumns"
                  :key="index"
                >
                  <v-checkbox
                    v-model="availableProviderColumns[index].active"
                    :label="$t(availableProviderColumns[index].text)"
                    @change="setAvailableProviderHeaders(availableProviderColumns[index],$event)"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>

          <v-row
            no-gutters
            v-if="providerList != null"
          >
            <v-col cols="12">
              <v-data-table
                v-model="selectedProviders"
                :headers="providerListHeaders"
                :items="filteredProviderList"
                :items-per-page="footerProps.itemsPerPage"
                :footer-props="footerProps"
                item-key="uid"
                show-select
                fixed-header
                :sort-by="providerListSortKey"
                :loading="$store.state.condAdmin.providersLoading"
                @update:items-per-page="getItemPerPage"
                @item-selected="setDirty"
              >
                <template v-slot:body.prepend="{}">
                  <tr>
                    <td></td>
                    <td
                      v-for="(h, index) in providerListHeaders"
                      :key="index"
                    >
                      <SearchField
                        v-if="providerFilter.hasOwnProperty(h.value)"
                        :value="providerFilter[h.value].value"
                        @filter="applyProviderFilter($event, h.value)"
                      />
                    </td>
                  </tr>
                </template>
                <template v-slot:header.data-table-select="{ on, props }">
                  <v-simple-checkbox
                    color="secondary"
                    v-bind="props"
                    v-on="on"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.state="{ item }">
                  <v-chip :class="getStateClass(item)">
                    {{ getStateText(item) }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row no-gutters>
            <v-card-title class="pa-0">
              {{ $t('condAdmin.moduleSettings.passThroughBrands') }}
            </v-card-title>
          </v-row>
          <v-row
            no-gutters
            class="py-4"
          >
            <QuickFilter
              :label="$t('base.selected')"
              :count="selectedBrands.length"
              :active="brandQuickFilter === true"
              :deselectValue="null"
              @clicked="applyBrandQuickFilter"
            />
            <QuickFilter
              class="ml-4"
              :label="$t('base.unselected')"
              :count="brandList.length - selectedBrands.length"
              :active="brandQuickFilter === false"
              :selectValue="false"
              :deselectValue="null"
              @clicked="applyBrandQuickFilter"
            />
            <v-spacer />
            <v-icon
              @click="init()"
              color="secondary"
              v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
            >mdi-reload</v-icon>
          </v-row>
          <v-row
            no-gutters
            v-if="brandList != null"
          >
            <v-col cols="12">
              <v-data-table
                v-model="selectedBrands"
                :headers="brandListHeaders"
                :items="filteredBrandList"
                :items-per-page="footerProps.itemsPerPage"
                :footer-props="footerProps"
                show-select
                fixed-header
                item-key="brand"
                :sort-by="brandListSortKey"
                :loading="$store.state.condAdmin.productsLoading"
                @update:items-per-page="getItemPerPage"
                @item-selected="setDirty"
              >
                <template v-slot:body.prepend="{}">
                  <tr>
                    <td></td>
                    <td
                      v-for="(h, index) in brandListHeaders"
                      :key="index"
                    >
                      <SearchField
                        v-if="brandFilter.hasOwnProperty(h.value)"
                        :value="brandFilter[h.value].value"
                        @filter="applyBrandFilter($event, h.value)"
                      />
                    </td>
                  </tr>
                </template>
                <template v-slot:header.data-table-select="{ on, props }">
                  <v-simple-checkbox
                    color="secondary"
                    v-bind="props"
                    v-on="on"
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <SaveCancelButtonBar
      class="pa-4"
      :cancel-text="$t('base.back')"
      :saveDisabled="!isDirty"
      @cancel="$emit('cancel')"
      @save="saveClientSettings()"
    />
  </v-card>
</template>

<script lang="js">
import { refreshProductList } from '@/condAdmin/js/condAdminHelper.js';
import { refreshProviderList } from '@/condAdmin/js/condAdminProviderHelper.js';
import { complexFilter } from '@/base/js/ObjectHelper';
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';
import condAdminMixin from '@/condAdmin/js/condAdminMixin.js';
import SearchField from '@/common/SearchField';
import QuickFilter from '@/common/QuickFilter';
export default {
  name: 'CondAdminModuleSettings_daag',
  components: {
    SaveCancelButtonBar,
    SearchField,
    QuickFilter
  },
  mixins: [ condAdminMixin ],
  props: {
    targetOrgUid: { type: String, required: true },
    parentOrgUid: { type: String, required: true }
  },
  data () {
    return {
      settings: null,
      isDirty: false,
      providerList: [],
      brandList: [],
      clientSettings: null,
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 50
      },
      selectedProviders: [],
      providerListSortKey: 'name',
      filteredProviderList: [],
      availableProviderColumns: [
        { text: this.$t('condAdmin.provider.key'), active: true, value: 'providerKey' },
        { text: this.$t('base.contact.name'), active: true, value: 'name' },
        { text: this.$t('base.contact.street'), active: false, value: 'street' },
        { text: this.$t('base.contact.zipCode'), active: false, value: 'zipCode' },
        { text: this.$t('base.contact.city'), active: true, value: 'city' },
        { text: this.$t('base.contact.country'), active: false, value: 'country' },
        { text: this.$t('base.contact.phone'), active: false, value: 'phone' },
        { text: this.$t('base.contact.email'), active: false, value: 'email' },
        { text: this.$t('base.contact.fax'), active: false, value: 'fax' },
        { text: this.$t('base.contact.additionalData'), active: false, value: 'additionalData' },
        { text: this.$t('base.state'), active: false, value: 'state' }
      ],
      providerQuickFilter: null,
      providerFilter: {
        providerKey: { condition: 'startsWith', value: null, type: 'SearchField' },
        name: { condition: 'startsWith', value: null, type: 'SearchField' },
        street: { condition: 'startsWith', value: null, type: 'SearchField' },
        zipCode: { condition: 'startsWith', value: null, type: 'SearchField' },
        city: { condition: 'startsWith', value: null, type: 'SearchField' },
        country: { condition: 'startsWith', value: null, type: 'SearchField' }
      },
      selectedBrands: [],
      brandListSortKey: 'brand',
      filteredBrandList: [],
      availableBrandColumns: [
        { text: this.$t('base.contact.name'), active: true, value: 'brand' }
      ],
      brandQuickFilter: null,
      brandFilter: {
        brand: { condition: 'startsWith', value: null, type: 'SearchField' }
      },
      rules: {
        required: (value) => !!value || this.$t('base.required')
      },
      texts: {
        de: {
          performance1: 'bis 99 Fahrzeuge',
          performance2: 'bis 250 Fahrzeuge',
          performance3: 'bis 750 Fahrzeuge',
          performance4: 'bis 1500 Fahrzeuge',
          performance5: 'bis 3000 Fahrzeuge',
          performance6: 'über 3000 Fahrzeuge'
        }
      }
    };
  },
  computed: {
    performanceList () {
      return [
        { text: this.texts.de.performance1, value: 1 },
        { text: this.texts.de.performance2, value: 2 },
        { text: this.texts.de.performance3, value: 3 },
        { text: this.texts.de.performance4, value: 4 },
        { text: this.texts.de.performance5, value: 5 },
        { text: this.texts.de.performance6, value: 6 }
      ];
    },
    providerListHeaders () {
      let h = [];
      for (let mc of this.availableProviderColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      return h;
    },
    brandListHeaders () {
      let h = [];
      for (let mc of this.availableBrandColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      return h;
    }
  },
  watch: {
    targetOrgUid () {
      this.init();
    },
    providerList () {
      this.filterProviders();
    },
    brandList () {
      this.filterBrands();
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    async init () {
      await Promise.all([
        this.getBrandList(),
        this.getProviderList(),
        this.getClientSettings()
      ]);
      this.syncSelectedObjects();
      this.filterProviders();
      this.filterBrands();
    },
    async getBrandList () {
      let productList = await refreshProductList(this.parentOrgUid);

      let list = [];
      for (let p of productList) {
        if (p.brand != null && !list.find((e) => e.brand === p.brand)) list.push({ brand: p.brand });
        if (p.brand == null && !list.find((e) => e.brand === this.NULLVALUE)) list.push({ brand: this.NULLVALUE });
      }
      this.brandList = list.sort();
    },
    async getProviderList () {
      this.providerList = await refreshProviderList(this.parentOrgUid);
    },
    async getClientSettings () {
      let ioData = {
        targetClientKey: this.targetOrgUid
      };
      this.$restClient.callProcess('condAdmin', 'getCondClientSettings', ioData, true).then(async (processData) => {
        if (isProcessStateOK(processData)) {
          this.clientSettings = processData.ioData.clientSettings;
          if (this.clientSettings.inheritedObjects == null) this.clientSettings.inheritedObjects = {};
          if (this.clientSettings.performance == null) this.clientSettings.performance = 3;
        } else {
          this.$globals.Info.open(processData.processState.messages);
        }
      });
    },
    syncSelectedObjects () {
      this.selectedProviders = [];
      if (this.clientSettings.inheritedObjects.provider != null) {
        for (let p of this.providerList) {
          if (this.clientSettings.inheritedObjects.provider.includes(p.uid)) this.selectedProviders.push(p);
        }
      }
      this.selectedBrands = [];
      if (this.clientSettings.inheritedObjects.brand != null) {
        for (let b of this.brandList) {
          if (this.clientSettings.inheritedObjects.brand.includes(b.brand)) this.selectedBrands.push(b);
        }
      }
    },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['providerTable'].footerPropItemsPerPage = val;
    },
    setAvailableProviderHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['providerTable'].columns[column.value] = value;
    },
    applyProviderQuickFilter (value) {
      this.providerQuickFilter = value;
      this.filterProviders();
    },
    applyProviderFilter (value, filtername) {
      this.providerFilter[filtername].value = value;
      this.filterProviders();
    },
    filterProviders () {
      let list = this.providerList;
      if (this.providerQuickFilter === true) {
        list = this.selectedProviders;
      } else if (this.providerQuickFilter === false) {
        list = list.filter((i) => this.selectedProviders.find((e) => i.uid === e.uid) == null);
      }
      this.filteredProviderList = complexFilter(list, this.providerFilter);
    },
    applyBrandQuickFilter (value) {
      this.brandQuickFilter = value;
      this.filterBrands();
    },
    applyBrandFilter (value, filtername) {
      this.brandFilter[filtername].value = value;
      this.filterBrands();
    },
    filterBrands () {
      let list = this.brandList;
      if (this.brandQuickFilter === true) {
        list = this.selectedBrands;
      } else if (this.brandQuickFilter === false) {
        list = list.filter((i) => this.selectedBrands.find((e) => i.brand === e.brand) == null);
      }
      this.filteredBrandList = complexFilter(list, this.brandFilter);
    },
    async saveClientSettings () {
      let messages = [];
      // check mandatory values
      if (this.rules.required(this.clientSettings.clientWebUrl) !== true) messages.push({ type: 'userError', message: this.$t('base.missingMandatory') + this.$t('condAdmin.moduleSettings.clientWebUrl') });

      if (messages.length > 0) {
        this.$globals.Info.open(messages);
        return null;
      } else {
        let providers = [];
        for (let p of this.selectedProviders) {
          providers.push(p.uid);
        }
        let brands = [];
        for (let b of this.selectedBrands) {
          brands.push(b.brand);
        }

        let ioData = {
          targetClientKey: this.targetOrgUid,
          parentKey: this.parentOrgUid,
          clientSettings: this.clientSettings
        };
        ioData.clientSettings.inheritedObjects.provider = providers;
        ioData.clientSettings.inheritedObjects.brand = brands;

        this.$restClient.callProcess('condAdmin', 'setCondClientSettings', ioData, true).then(async (processData) => {
          if (isProcessStateOK(processData)) {
            this.$globals.Confirm.okDlg(this.$t('accAdmin.org.updateData'), this.$t('accAdmin.org.updateSuccess'));
          } else {
            this.$globals.Info.open(processData.processState.messages);
          }
        });
      }
    },
    setDirty () {
      if (!this.isDirty) {
        console.log('set Dirty');
        this.isDirty = true;
      }
    }
  }
};
</script>

<style scoped>
</style>
