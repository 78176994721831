import { render, staticRenderFns } from "./condAdminModuleSettings_daag.vue?vue&type=template&id=28556186&scoped=true"
import script from "./condAdminModuleSettings_daag.vue?vue&type=script&lang=js"
export * from "./condAdminModuleSettings_daag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28556186",
  null
  
)

export default component.exports