<template>
  <v-chip
    class="px-4 clickable"
    color="primary lighten-2"
    :outlined="isActive !== true"
    @click="clicked"
  >
    <v-icon left>
      mdi-magnify
    </v-icon>
    {{ label  + (count != null ? ' (' + count + ')' : '') }}
  </v-chip>
</template>

<script lang="js">
export default {
  name: 'QuickFilter',
  props: {
    label: { type: String, required: true },
    active: { type: Boolean, default: false },
    count: { type: Number, default: null },
    selectValue: { default: true },
    deselectValue: { default: false }
  },
  data () {
    return {
      isActive: false
    };
  },
  watch: {
    active () {
      this.isActive = this.active;
    }
  },
  mounted () {
    this.isActive = this.active;
  },
  methods: {
    clicked () {
      this.isActive = !this.isActive;
      this.$emit('clicked', this.isActive ? this.selectValue : this.deselectValue);
    }
  }

};
</script>

<style scoped>
</style>
