import Vue from 'vue';
import { store } from '@/plugins/store';
import { isProcessStateOK, newMessage } from '@/base/js/ProcessDataHelper';
import { countrySettings } from '@/base/js/baseHelper.js';
import deepmerge from '@/base/js/Deepmerge.js';

/* *****************************************************
 * Provider
***************************************************** */
const defaultCalcSettings = () => {
  return {
    countryKey: countrySettings[0].countryKey,
    currency: countrySettings[0].currency,
    currencyCent: countrySettings[0].currencyCent,
    vat: countrySettings[0].vat,
    balloonOption: 'ENDVALUE',
    customCalcType: ''
  };
};
export const refreshProviderList = async (clientKey = null) => {
  if (clientKey == null) store.state.condAdmin.providerList = [];
  store.state.condAdmin.providersLoading = true;
  let ioData = {
    clientKey: clientKey || store.state.condAdmin.clientKey
  };

  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', 'getProviderList', ioData);
  if (isProcessStateOK(processData)) {
    if (clientKey == null) store.state.condAdmin.providerList = processData.ioData.providerList;
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
  store.state.condAdmin.providersLoading = false;
  return processData.ioData.providerList;
};

export const copyProvider = (source = {}) => {
  return {
    uid: source.uid != null ? source.uid : null,
    name: source.name != null ? source.name : '',
    providerKey: source.providerKey != null ? source.providerKey : '',
    street: source.street != null ? source.street : '',
    zipCode: source.zipCode != null ? source.zipCode : '',
    city: source.city != null ? source.city : '',
    country: source.country != null ? source.country : '',
    phone: source.phone != null ? source.phone : '',
    email: source.email != null ? source.email : '',
    fax: source.fax != null ? source.fax : '',
    additionalData: source.additionalData != null ? source.additionalData : '',
    calcSettings: source.calcSettings != null ? deepmerge(defaultCalcSettings(), source.calcSettings) : defaultCalcSettings()
  };
};

export const saveProvider = async (provider) => {
  // validate fields
  let messages = [];
  if (provider.name.trim().length === 0) {
    messages.push(newMessage('userError', Vue.prototype.$t('base.missingMandatory') + Vue.prototype.$t('base.contact.name')));
  } else if (providerNameUniqueCheck(provider) != null) {
    messages.push(newMessage('userError', Vue.prototype.$t('condAdmin.provider.nameAlreadyExists', { name: provider.name })));
  }

  if (provider.providerKey.trim().length === 0) {
    messages.push(newMessage('userError', Vue.prototype.$t('base.missingMandatory') + Vue.prototype.$t('condAdmin.provider.key')));
  } else if (providerKeyUniqueCheck(provider) != null) {
    messages.push(newMessage('userError', Vue.prototype.$t('condAdmin.provider.keyAlreadyExists', { key: provider.providerKey })));
  }

  if (messages.length > 0) {
    Vue.prototype.$globals.Info.open(messages);
    return false;
  }

  let returnState = false;
  const ioData = {
    clientKey: store.state.condAdmin.clientKey,
    provider: provider
  };
  let processName = 'updateProvider';
  if (provider.uid == null) {
    processName = 'createProvider';
  }
  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', processName, ioData, true);
  if (isProcessStateOK(processData)) {
    if (processName === 'createProvider') {
      store.state.condAdmin.providerList.push(processData.ioData.provider);
    } else {
      let pIndex = store.state.condAdmin.providerList.findIndex((p) => p.uid === processData.ioData.provider.uid);
      store.state.condAdmin.providerList[pIndex] = processData.ioData.provider;
    }
    returnState = true;
  }
  Vue.prototype.$globals.Info.open(processData.processState.messages, true);
  return returnState;
};

export const providerKeyUniqueCheck = (provider) => {
  let existing = store.state.condAdmin.providerList.find((i) => i.providerKey === provider.providerKey && i.uid !== provider.uid);
  return existing;
};

export const providerNameUniqueCheck = (provider) => {
  let existing = store.state.condAdmin.providerList.find((i) => i.name === provider.name && i.uid !== provider.uid);
  console.log('Existing Provider:', JSON.stringify(existing));
  return existing;
};
